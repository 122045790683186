import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

// import AnimatedCursor from '../utils/react-animated-cursor'

import '../assets/scss/main.scss'
import Header from '../components/header'

//const AnimatedCursor = React.lazy(() => import('../utils/react-animated-cursor'));

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isSSR = typeof window === "undefined";

  return (
    <>
      <div className='site-wrapper'>
        {/* {typeof window !== 'undefined' && ( */}
        <Suspense fallback={null}>
          {/* {!isSSR && (
            <AnimatedCursor
              innerSize={15}
              outerSize={0}
              color='28, 27, 27'
              outerAlpha={1}
              innerScale={0.7}
              outerScale={0}
            />
          )} */}
          <Header {...data} />
        </Suspense>
        {/* )} */}
        <main>{children}</main>
        {/* <Footer {...data} /> */}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
