import React, { Component } from "react"

import Link from '../utils/link'

class Header extends Component {

  state = {
    offCanvas: false,
    // scrolled: false,
  }

  componentDidMount() {
    window && window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window && window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 1
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window && window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  render() {
    let { offCanvas } = this.state

    // let props = {
    //   activeClassName: 'active',
    //   onClick: (event) => {
    //     this.setState({ offCanvas: false })
    //   },
    // }

    // let headerClass = 'home-logo'
    // //let headerClass = 'home-logo blend'
    // if (scrolled) headerClass += ' page--scrolled'

    return (
      <>
        <header className={`blend header ${offCanvas ? 'header--open' : ''}`}>
          <div className='header__inner'>
            <nav className='header__nav'>
              <ul>
                <li className='header__nav--showcase'>
                  <Link to='/'>StudioSmall<span>,</span></Link>
                </li>
                <li className='header__nav--projects'>
                  <Link to='/projects/selected'>Projects</Link>
                </li>
              </ul>
            </nav>

            <nav className='header__right'>
              <ul>
                <li><Link to='/information'>Information</Link></li>
              </ul>
            </nav>

          </div>
        </header>

        {/*
          <div className={headerClass}>
          <img src={Logo} alt='StudioSmall' />
        </div>
*/
        }
        {/* <AnimatedCursor
            innerSize={8}
            outerSize={8}
            outerAlpha={0.2}
            color='135, 135, 135'
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={3}
          /> */}
      </>
    )
  }
}

export default Header